





























































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import PurchaseOrderTableItem from "@/components/purchase-order/PurchaseOrderTableItem.vue"
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "purchaseOrderNumberHistory" as string,

  components: {
    Layout,
    "search-bar": SearchBar,
    "purchase-order-table-item": PurchaseOrderTableItem,
  },

  data() {
    return {
      title: "Purchase order history",
      currentPage: 1,
      perPage: 10,
    };
  },

  async mounted(): Promise<void> {
    await this.fetchPurchaseOrderNumbers({pageNumber: this.currentPage, venueOwnerId: this.$route.params.id});
  },

  computed: {
    ...mapGetters("azPurchaseOrderModule", {
      purchaseOrderNumbers: "GET_PURCHASE_ORDER_NUMBERS",
      purchaseOrderTotalCount: "GET_PURCHASE_ORDER_TOTAL_COUNT"
    }),
    totalPurchaseOrderNumbers(): number {
      return this.purchaseOrderTotalCount;
    },
  },

  methods: {
    ...mapActions("azPurchaseOrderModule", {
      fetchPurchaseOrderNumbers: "FETCH_PURCHASE_ORDER_NUMBERS"
    }),
    resetPagination(): void {
      this.currentPage = 1;
    },
    async updatePurchaseOrderNumbers(){
      this.resetPagination();
      await this.fetchPurchaseOrderNumbers({pageNumber: this.currentPage, venueOwnerId: this.$route.params.id});
    }
  },

  watch: {
    '$route.params.id': {
      handler: async function(){
        await this.fetchPurchaseOrderNumbers({pageNumber: this.currentPage, venueOwnerId: this.$route.params.id});
      },
      deep: true,
      immediate: true
    },
    currentPage: {
      handler: async function(){
        await this.fetchPurchaseOrderNumbers({pageNumber: this.currentPage, venueOwnerId: this.$route.params.id});
      },
      deep: true,
      immediate: true
    }
  }
});
