































import Vue, {PropType} from "vue";
import {formatUnitPrice} from "@/utils/helpers";
import {mapActions} from "vuex";
import { PurchaseOrderData } from "@/types/purchase_order";
import { ToggleButton } from "vue-js-toggle-button";
import {successMessage, errorMessage} from "@/utils/messages-handlers";

export default Vue.extend({
  name: "billing-invoice-table-item" as string,

  components: {
    ToggleButton,
  },

  props: {
    data: Object as PropType<PurchaseOrderData>
  },

  computed: {
    invoicePaymentType(): string {
      return this.data.paymentType === 1 ? this.$t('Credit/Debit card') : this.$t('Invoice');
    },
    subscriptionPeriodDate(): string {
      return this.formatDate(this.data.startSubscriptionPeriodDate) + " - " + this.formatDate(this.data.endSubscriptionPeriodDate);
    }
  },

  methods: {
    ...mapActions("azPurchaseOrderModule",{
      enablePONumber: "ENABLE_PURCHASE_ORDER_NUMBER",
      disableAllPONumbers: "DISABLE_ALL_PURCHASE_ORDER_NUMBERS"
    }),
    async enablePurchaseOrderNumber(event){
      if(event.value){
        const currentTimeStamp = Math.floor(Date.now() / 1000);
        if(this.data.validTo && this.data.validTo < currentTimeStamp){
          errorMessage('Expired purchase order number cannot be set as active.', 5000);
          return;
        }
        await this.enablePONumber(this.data.id)
        successMessage('Purchase order number enabled successfully.', 5000)
        this.$emit('fetchPurchaseOrderNumbers')
      } else {
        await this.disableAllPONumbers(this.$route.params.id)
        successMessage('All purchase order numbers were disabled.', 5000)
        this.$emit('fetchPurchaseOrderNumbers')
      }
    },
    formatDate(value: number): string {
      const date = new Date(Number(value) * 1000);
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();

      return ('0' + day).slice(-2) + '.' + ('0' + (month)).slice(-2) + '.' + year;
    },
    statusVariant(status: string): string {
      switch (status) {
        case "paid":
          return "success";
        case "cancelled":
          return "cancelled";
        case "trialing":
          return "warning";
        case "active":
          return "primary";
        case "open":
          return "info";
        case "credit note":
          return "credit-note";
        case "due":
          return "due";
        case "uncollectible":
          return "uncollectible";
        case "past due":
          return "past-due";
        default:
          return "";
      }
    }
  }
})
